.container__welcome {
  position: relative;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container__welcome::-webkit-scrollbar{
  display: none;
}
.mvp-logo__welcome {
  margin: 1rem 0;
}

.content__welcome {
  width: 100%;
  height: auto;
}

.TopContentContainer__welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.above-logo-text__welcome {
  color: white;
  line-height: 3.5rem;
  font-weight: 700;
  font-size: 1.6rem;
}

.below-logo-text__welcome {
  color: white;
  font-size: 2em;
  font-weight: 700;
  line-height: 3rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.inside-below-logo-text__welcome {
  font-weight: 800;
  font-size: 2.25rem;
  white-space: nowrap;
  line-height: 2.6rem;
  color: #79C001;
}

.welcome-items__welcome {
  padding: 0 1em;
}

.welcomeBtn__welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}

.button__welcome {
  padding: 0.5rem 2rem;
  background: #0f57fb;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
  border-radius: 28px;
  color: white;
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 2.7rem;
}

.bottomLogo__welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 10px;
}

@media all and (min-height: 800px) {
  .bottomLogo__welcome {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

@media all and (min-width: 768px) {

  .mvp-logo__welcome {
    margin: unset;
  }

  .content__welcome {
    width: 110%;
  }

  .TopContentContainer__welcome {
    margin-top: 100px;
  }

  .welcome-items__welcome {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 1.5rem;
  }

  .above-logo-text__welcome {
    font-size: 3rem;
    line-height: 4.5rem;
  }

  .below-logo-text__welcome {
    font-size: 3rem;
    line-height: 4.5rem;
  }

  .inside-below-logo-text__welcome {
    color: #79c001;
    /* font-size: 1.3em; */
    font-size: 4rem;
  }

  .bottomLogo__welcome {
    position: unset;
    margin-bottom: -30px;
  }
}

@media all and (min-width: 1350px) {
  .content__welcome {
    width: 75%;
  }
}