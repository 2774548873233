.title__DesktopShare {
  white-space: pre-line;
  margin-top: 1rem;
  /* font-weight: bold; */
  font-size: 20px;
  line-height: 1.875rem;
  align-items: center;
  text-align: center;
}

.copy_link_box__DesktopShare {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.url2Share__DesktopShare {
  background-color: #f4f4f4;
  /* padding: 0.7rem 3rem; */
  border: 1px solid #c2c2c2;
  border-radius: 1.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.url2Share__DesktopShare a {
  color: #79c000;
  text-decoration: underline;
  margin: 0.7rem 6rem 0.7rem 1.5rem;
  text-align: left;
}

.url2Share__DesktopShare a:hover {
  color: #79c000;
}

.copyBtn__DesktopShare {
  /* width: 30%; */
  width: 6.25rem;
  height: 3.125rem;
  border: 0;
  padding: 0.7rem 2rem;
  border-radius: 1.25rem;
  color: white;
  background: linear-gradient(123.87deg, #353e4b 5.44%, #243b5f 92.75%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.exitBtn__DesktopShare {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: transparent;
  color: rgba(53, 61, 73, 0.6);
  border: none;
}

.socialIcons_container__DesktopShare {
  width: 100%;
  display: flex;
  justify-content: center;
}

.snapShot_Box__DesktopShare {
  width: 100%;
  display: flex;
  justify-content: center;
}

.img_box__DesktopShare {
  position: relative;
  display: flex;
  justify-content: center;
  width: 80%;
}
.img_box__DesktopShare .snapshot__DesktopShare {
  max-height: 100%;
  max-width: 100%;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 10px rgba(175, 175, 175, 0.4));
  border-radius: 20px;
}

.blackSlip__DesktopShare {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
  background-color: rgba(51, 64, 73, 0.7);
  border-radius: 0px 0px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blackSlip__DesktopShare:hover {
  cursor: pointer;
}
.blackSlip__DesktopShare img {
  max-height: 50%;
}
.blackSlipText__DesktopShare {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
}
