@keyframes Slide_Up {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

.fadedBackground__MobileShare {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 1040;
}

.container__MobileShare {
  position: relative;
  animation: Slide_Up 0.3s ease-in;
  top: 0;
  max-height: 50%;
  width: 100%;
  padding: 1.4rem 0.5rem;
  gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.title__MobileShare {
  /* margin-top: 1.5rem; */
  font-weight: bold;
  font-size: 1.15rem;
  /* line-height: 23px; */

  text-align: center;
  padding: 0 10px;
}

.copy_link_box__MobileShare {
  display: flex;
  justify-content: space-between;
  gap: 0.3rem;
  align-items: center;
}

.url2Share__MobileShare {
  background-color: #f4f4f4;
  /* padding: 0.7rem 3rem; */
  border: 1px solid #c2c2c2;
  border-radius: 1.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;

  /* margin: 0 0.1rem; */
}

.url2Share__MobileShare a {
  color: #79c000;
  text-decoration: underline;
  margin: 0.7rem 0.4rem 0.7rem 1rem;
  text-align: left;
}

.url2Share__MobileShare a:hover {
  color: #79c000;
}

.copyBtn__MobileShare {
  width: 20%;
  height: 3rem;
  border: 0;
  padding: 0.7rem 2rem;
  border-radius: 1.25rem;
  color: white;
  background: linear-gradient(123.87deg, #353e4b 5.44%, #243b5f 92.75%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.exitBtn__MobileShare {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: transparent;
  color: rgba(53, 61, 73, 0.6);
  border: none;
}

.socialIcons_container__MobileShare {
  width: 100%;
  display: flex;
  justify-content: center;
}

.snapShot_Box__MobileShare {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.img_box__MobileShare {
  width: 90%;
  padding: 1rem;

}

.img_box__MobileShare img {
  max-width: 100%;
  max-height: 100%;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 10px rgba(175, 175, 175, 0.4));
  border-radius: 20px;
}